import AccountProcessStepRegister from 'yinzhilv-js-sdk/frontend/common-project/component/haolv/12306-account-process-step/12306-account-process-step-register/1.0.0/index.vue'

export default {
  data() {
    return {
      failDialogVisible: false
    }
  },
  activated() {
    this.$refs['accountProcessStepRegister'].init()
  },
  components: {
    AccountProcessStepRegister
  },
  methods: {
    registerToCode(data) {
      this.$router.push({
        name: 'admin-train-account-code',
        query: {
          name: data.form.name ? data.form.name : '',
          idNo: data.form.idNo ? data.form.idNo : '',
          phoneNo: data.form.phoneNo ? data.form.phoneNo : '',
        }
      })
    },
    failSubmit() {
      this.failDialogVisible = false;
      this.back();
    },
    beforeBack() {
      this.failDialogVisible = true;
    },
    back() {
      this.$router.go(-1);
    },
  },
};
